import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import ResourcesContent from '../components/resources/resources'

const Contact = ({ data }) => (
  <>
    <SEO {...data.wordpressPage.yoast_meta} title="data.wordpressPage.title" />
    <ResourcesContent {...data.wordpressPage.acf.resources} />
  </>
)

export default Contact

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...ResourcesFragment
      }
    }
  }
`
