import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { Waypoint } from 'react-waypoint'
import { imageMask } from './animation/animations'

const AnimatedImage = ({ imageFluid }) => {
  const [animation, setAnimation] = useState(undefined)

  const handleAnimation = () => {
    setAnimation(true)
  }

  return (
    <>
      <Waypoint onEnter={() => handleAnimation()} scrollableAncestor="window" bottomOffset="45%" />
      <ImageMask animate={animation ? 'hidden' : 'visible'} variants={imageMask} />
      <ImageEl fluid={imageFluid} />
    </>
  )
}

export default AnimatedImage

const ImageMask = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.black};
  z-index: 5;
`

const ImageEl = styled(Img)`
  z-index: 1;
`
