import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { encode } from 'base-64'
import { useInput } from '../../hooks/input-hook'
import { fadeInUp } from '../animation/animations'

const ContactForm = ({ data, title, id }) => {
  const [success, setSuccess] = useState(false)

  const { value: name, bind: bindName, reset: resetName } = useInput('')
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')

  const handleFormSubmit = event => {
    event.preventDefault()
    resetName()
    resetEmail()

    const formData = JSON.stringify({
      form_id: id,
      '1': `${name}`,
      '2': `${email}`
    })

    const username = data.site.siteMetadata.gf_consumer_key
    const password = data.site.siteMetadata.gf_consumer_secret

    fetch(data.site.siteMetadata.gf_entries_endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${encode(`${username}:${password}`)}`,
        'Content-Type': 'application/json'
      },
      body: formData
    })
      .then(response => response.json())
      .then(responseData => {
        console.log(responseData)
        setSuccess(true)
        fetch(`${data.site.siteMetadata.gf_entries_endpoint}/${responseData.id}/notifications`, {
          method: 'POST',
          headers: {
            Authorization: `Basic ${encode(`${username}:${password}`)}`,
            'Content-Type': 'application/json'
          }
        })
      })
      .catch(function(error) {
        console.log('Request failure: ', error)
      })
  }

  return (
    <Outer>
      <Inner className="container">
        <Title>{title}</Title>
        <FormWrap>
          <AnimatePresence>
            {success && (
              <FormBlock>
                <FormSuccess
                  initial="hidden"
                  animate={success ? 'visible' : 'hidden'}
                  exit="exit"
                  variants={fadeInUp}
                >
                  Thank you for your interest in this resource. Please check your email for the
                  download link.
                </FormSuccess>
              </FormBlock>
            )}
          </AnimatePresence>
          <Form onSubmit={handleFormSubmit} className={success ? 'blur' : ''}>
            <label>
              <span>Name</span>
              <input type="text" id="name" required {...bindName} />
            </label>
            <label>
              <span>Email</span>
              <input type="email" id="email" required {...bindEmail} />
            </label>
            <button className="button" type="submit">
              submit
            </button>
          </Form>
        </FormWrap>
      </Inner>
    </Outer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            gf_consumer_key
            gf_consumer_secret
            gf_entries_endpoint
          }
        }
        wordpressAcfOptions {
          global_options {
            contactDetails {
              email
              phoneNumber
            }
          }
        }
      }
    `}
    render={data => <ContactForm data={data} {...props} />}
  />
)

const FormSuccess = styled(motion.p)`
  width: 100%;
  max-width: 36rem;
  padding: 0 2rem;
  font-size: 18px;
  text-align: center;
  line-height: 1.4;
  font-weight: 600;
  margin: 0;
  z-index: 5;
`

const FormBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`

const FormWrap = styled.div`
  position: relative;
  z-index: 1;
`

const Outer = styled.section`
  position: relative;
  margin: 0 0 15rem;

  z-index: 5;

  @media (max-width: 1200px) {
    margin: 0 0 10rem;
  }

  @media (max-width: 1000px) {
    margin: 0 0 5rem;
  }
`

const Inner = styled.div`
  max-width: 71rem;
`

const Title = styled.h2`
  font-size: 6.6rem;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 3rem;

  @media (max-width: 650px) {
    font-size: 4rem;
  }
`

const Form = styled.form`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  transition: filter 0.3s ease;
  z-index: 1;

  &.blur {
    filter: blur(5px);
    pointer-events: none;
  }

  > label {
    display: flex;
    flex-direction: column;
    width: 46%;
    margin-bottom: 5rem;
    font-size: 2.2rem;
    font-weight: 100;
    letter-spacing: 0.1rem;
    color: #fff;

    &:focus-within {
      span {
        transform: scale(0.6);
      }
    }

    span {
      transition: transform 0.3s ease;
      transform-origin: bottom left;
    }
  }

  input {
    border: 0;
    color: #fff;

    &:focus {
      outline: 0;
    }

    &[type='text'],
    &[type='email'] {
      font-size: 1.8rem;
      font-weight: 100;
      letter-spacing: 0.1rem;
      border-radius: 0;
      background: transparent;
      border-bottom: 0.1rem solid ${props => props.theme.colours.primary};
      margin-top: 1rem;
      transition: border 0.3s ease;

      &:focus {
        border-bottom: 0.1rem solid ${props => props.theme.colours.secondary};
      }
    }
  }
`
