import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import AnimatedImage from '../animatedimage'
import Title from '../title'
import Button from '../button'
import FormModal from './form-modal'

export const fragment = graphql`
  fragment ResourcesFragment on wordpress__PAGEAcf {
    resources {
      title
      resources {
        title
        intro
        mainContent
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        form
      }
      backgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ResourcesContent = ({ title, resources, backgroundImage }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [currentFormId, setCurrentFormId] = useState(null)

  const handleContactModalOpen = formId => {
    setContactModalOpen(!contactModalOpen)

    if (formId) {
      setCurrentFormId(formId)
    }
  }

  // Ensure inital animations are fired
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 500)
    }
  }, [])

  return (
    <>
      <Outer>
        <Title text={title} />
        <Inner>
          {resources.map((resource, i) => (
            <React.Fragment key={i}>
              <ServiceRow>
                <ImageContainer>
                  <AnimatedImage imageFluid={resource.image.localFile.childImageSharp.fluid} />
                </ImageContainer>
                <Text>
                  <TextInner>
                    <ServiceSmallTitle>Resource</ServiceSmallTitle>
                    <ServiceTitle>{resource.title}</ServiceTitle>
                    <ServiceIntro>{resource.intro}</ServiceIntro>
                    <ServiceContent>{resource.mainContent}</ServiceContent>
                    <Button
                      type="button"
                      click={() => handleContactModalOpen(resource.form)}
                      text="read more"
                    />
                  </TextInner>
                </Text>
              </ServiceRow>
            </React.Fragment>
          ))}
        </Inner>
        <Image
          fluid={backgroundImage.localFile.childImageSharp.fluid}
          style={{ position: 'absolute' }}
        />
      </Outer>

      <FormModal
        title="Request download"
        id={currentFormId}
        isOpen={contactModalOpen}
        handleContactModalOpen={handleContactModalOpen}
      />
    </>
  )
}

const Outer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 15rem;

  h2 {
    position: relative;
    font-size: 6.6rem;
    line-height: 1.4;
    text-align: center;
    padding: 0 3rem;
    margin-bottom: 12rem;
    z-index: 10;

    @media (max-width: 650px) {
      font-size: 4rem;
    }
  }
`

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
`

const Image = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
`

const ImageContainer = styled.div`
  position: relative;
  width: 46.5%;
  overflow: hidden;

  @media (max-width: 650px) {
    width: 100%;
  }
`

const Text = styled.div`
  width: 46.5%;
  padding-top: 12rem;

  @media (max-width: 650px) {
    width: 100%;
  }
`

const TextInner = styled.div`
  max-width: 49rem;
  padding-right: 3rem;

  @media (max-width: 650px) {
    max-width: 60rem;
    margin: 0 auto;
    padding: 0 3rem;
  }

  button {
    @media (max-width: 650px) {
      margin-top: 3rem;
    }
  }
`

const ServiceSmallTitle = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1rem;
`

const ServiceTitle = styled.h3`
  font-size: 4.6rem;
  line-height: 1.2;
`

const ServiceIntro = styled.p`
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 100;
`

const ServiceContent = styled.p`
  font-size: 1.6rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 2rem;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`

const ServiceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12rem;

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 650px) {
      flex-direction: row;
    }

    ${TextInner} {
      max-width: 54rem;
      margin: 0 auto;
      padding: 0 3rem;

      @media (max-width: 650px) {
        max-width: 60rem;
        margin: 0 auto;
        padding: 0 3rem;
      }
    }
  }
`

export default ResourcesContent
